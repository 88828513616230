const defaultMessages = require("../../messages.json");

module.exports = Object.assign({}, defaultMessages, {
  "general.brand": "Dapian",

  "model.case.details": "Information Asset",
  "model.case.searchable": "Visible outside organisation",
  "model.case": "Asset",

  "model.notification.EMAIL_CASES_DUE_FOR_REVIEW": "Email notification of any visible Assets due for review",

  "nav.assessment": "Add asset",
  "nav.assessment.description": "Register a new information asset",
  // TODO: this makes no sense for IAR flow. What should we tell the user in this step?
  "nav.assessment.step.last.description": "If the screening tool suggested that a IAR is required, you need to complete the relevant IAR form. If you assign activity to other users for their input, you are able to login and check the progress of the activity, otherwise notifications will be sent to your registered email address when the activity status' changes.",

  "nav.cases": "Information Asset Register",
  "nav.cases.notfound": "No assets found",

  "nav.cases.internal.search": "Search assets",
  "nav.cases.internal.search.description": "A list of all assets",
  "nav.cases.external.search": "Search other organisations’ assets",
  "nav.cases.edit": "Edit Information Asset",
  "nav.cases.show": "Asset",
  "nav.cases.history": "Asset history",
  "nav.cases.add": "Add asset",
  "nav.cases.status.edit": "Change Asset status",
  "nav.cases.status.edit.notifications.description": "System will attempt to send notifications to the asset creator and any assignees currently working on activities. Comment will be part of that notification text.",
  "nav.cases.priority.edit": "Change asset priority",
  "nav.cases.clone": "Clone asset",
  "nav.cases.user": "Your assets",
  "nav.cases.links": "Related asset",
  "nav.cases.links.notfound": "No related assets found",
  "nav.cases.links.add": "Link related asset",

  "nav.export.cases": "Export information assets",
  "nav.export.tasks": "Export processing activities",

  "nav.tasks.all.description": "Search all assigned activities",
  "nav.tasks.forms": "Available forms",
  "nav.tasks.forms.description": "Activities relevant to the asset",
  "nav.tasks.add.step.case.status": "Update Asset status",
  "nav.tasks": "Processing activities",
  "nav.tasks.show": "Activity",
  "nav.tasks.add": "Add new activity",
  "nav.tasks.status.edit": "Change activity status",
  "nav.tasks.history": "Activity history",
  "nav.tasks.history.show": "Activity history snapshot",
  "nav.tasks.all": "All activities",

  "nav.profiles.add.description": "Add new user profiles and admin rights",
  "nav.profiles.assigned.task": "Assigned activites",
  "nav.profiles.re-assign": "Reassign activities",

  "nav.whats_new_in_product": "What's new in the Information Asset Register",

  "stats.cases.by.user": "Assets created by you",
  "stats.cases.by.user.notfound": "No assets found",
  "stats.cases.unresolved.age": "Age of live assets",
  "stats.cases.unresolved.age.items": "Number of assets",
  "stats.cases.daily": "Daily assets",
  "stats.cases.daily.items": "Number of assets",
  "stats.tasks.assigned.user": "Activities assigned to you",
  "stats.tasks.assigned.user.notfound": "No activities found"
});
