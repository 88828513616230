import React from 'react';
import { Link } from 'react-router-dom';
import { WithPermission } from '../../../auth/AccessConditionals';

/**
 * Following ES6 modules work in the context of react-scripts only, that depend on Webpack.
 * If they need to be used in CommonJS environment then refactor to use React.createClass
 */

const getLikelihoodText = (val) => {
  if (val === 1) return "Rare";
  else if (val === 2) return "Unlikely";
  else if (val === 3) return "Possible";
  else if (val === 4) return "Likely";
  else if (val === 5) return "Certain";
  else return "";
};

const getSeverityText = (val) => {
  if (val === 1) return "Insignificant";
  else if (val === 2) return "Minor";
  else if (val === 3) return "Moderate";
  else if (val === 4) return "Major";
  else if (val === 5) return "Severe";
  else return "";
};

const getRiskText = (val) => {
  if (val === 1) return "Low";
  else if (val === 2) return "Medium";
  else if (val === 3) return "High";
  else return "";
};

const getBooleanText = (model, path) => {
  let properties = Array.isArray(path) ? path : path.split(".");
  let data = properties.reduce((prev, curr) => prev && prev[curr], model);
  if (data !== undefined && data !== " ") {
    if (data === true) return "Yes";
    else return "No";
  }
  else return "";
};

const QuestionBlock = (props) => {
  return (
    <div className="form-field my-2">
      <div className="form-field-label">
        <label className="m-0">{props.question}</label>
      </div>
      <div><div className="form-field-value">{props.answer}<br/></div></div>
    </div>);
};

const getAssetRisk = (model) => {
  return(<>
    <u>2. Asset Specific Risks</u><br/>
    {model.risks && model.risks.map((x, index) => {
      return <div key={index}>
        <QuestionBlock question="2.1. Describe the risk" answer={x.description} />
        <QuestionBlock question="2.2. What is the potential impact?" answer={x.impact} />
        <QuestionBlock question="2.3. What is the likelihood of harm before identifying ways to reduce the risk?" answer={getLikelihoodText(x.preMitigationLikelihood)} />
        <QuestionBlock question="2.4. What is the severity of harm likely to be before identifying ways to reduce the risk?" answer={getSeverityText(x.preMitigationSeverity)} />
        <QuestionBlock question="2.5. What action needs to be taken to reduce or eliminate the risk?" answer={x.mitigation} />
        <QuestionBlock question="2.6. Who owns the action?" answer={x.actionOwner} />
        <QuestionBlock question="2.7. What date will this be done?" answer={x.actionDate} />
        <QuestionBlock question="2.8. What is the likelihood of harm after reducing the risk?" answer={getLikelihoodText(x.postMitigationLikelihood)} />
        <QuestionBlock question="2.9. What is the severity of harm likely to be after reducing the risk?" answer={getSeverityText(x.postMitigationSeverity)} />
        <QuestionBlock question="2.10. What is the overall risk to the organisation?" answer={getRiskText(x.overallRisk)} />
        <br/>
      </div>;
    })}
  </>);
};

export const CaseDetails = ({ model, ...rest }) => {
  return <div {...rest}>
    <address className="read-only-form">
      <u>Record Description</u><br/>
      <QuestionBlock question="Name of Information Asset" answer={model.assetName} />
      <QuestionBlock question="Owner of Information Asset" answer={model.assetOwner} />
      <QuestionBlock question="Administrator of Information Asset" answer={model.assetAdministrator} />
      <QuestionBlock question="Service area / Team / Department" answer={model.assetPlace} />
      <QuestionBlock question="Describe what the information asset is for" answer={model.assetDescription} />
      <hr />
      <u>1. Details of the data</u><br/>
      <QuestionBlock question="1.1 Details of the data" answer={model.assetData?.dataDetails} />
      <QuestionBlock question="1.2 Where is the data stored?" answer={model.assetData?.dataStorage} />
      <QuestionBlock question="1.3 Please state from where the personal data originates" answer={model.assetData?.dataOrigins} />
      <QuestionBlock question="1.4 How many records does the asset contain?" answer={model.assetData?.assetRecords} />
      <QuestionBlock question="1.5 What is the data retention policy for the asset?" answer={model.assetData?.dataRetentionPolicy} />
      <QuestionBlock question="1.6 Has a privacy notice been completed?" answer={getBooleanText(model, "assetData.privacyNoticeCompleted")} />
      <QuestionBlock question="1.7 Is this a key asset?" answer={getBooleanText(model, "assetData.isKeyAsset")} />
      <QuestionBlock question="1.8 Does the asset contain personal data?" answer={getBooleanText(model, "assetData.assetContainsPersonalData")} />
      <QuestionBlock question="1.9 Do you have opt-in consent for the processing of Personal Data?" answer={getBooleanText(model, "assetData.personalDataConsent")} />
      <QuestionBlock question="1.10 Do you have opt-in consent for the processing of Sensitive Data?" answer={getBooleanText(model, "assetData.sensitiveDataConsent")} />
      {model.assetData?.personalDataConsent && <QuestionBlock question="1.11 Which of the following condition(s) are you relying upon?" answer={model.assetData?.personalDataConditions} />}
      {model.assetData?.sensitiveDataConsent && <QuestionBlock question="1.12 Which of the following condition(s) are you relying upon?" answer={model.assetData?.sensitiveDataConditions} />}
      <hr />
      {getAssetRisk(model)}
      <hr />
      <u>3. Information Sharing and Data Protection Impact Assessments</u><br/>
      <QuestionBlock question="3.1 Has a data protection impact assessment been completed?" answer={getBooleanText(model, "iarImpactAssessments.iarCompleted")} />
      <QuestionBlock question="3.2 Is the information shared with other parties?" answer={getBooleanText(model, "iarImpactAssessments.infoSharedOtherParties")} />
      <QuestionBlock question="3.3 Is the information shared internally?" answer={getBooleanText(model, "iarImpactAssessments.infoSharedInternally")} />
      <QuestionBlock question="3.4 Is the information shared externally?" answer={getBooleanText(model, "iarImpactAssessments.infoSharedExternally")} />
    </address>
    <WithPermission name="case:read">
      <SimilarCasesLink model={model}/>
    </WithPermission>
  </div>;
};

export { HomePageContent } from './HomePageContent';
export { AboutPageContent } from './AboutPageContent';

const SimilarCasesLink = ({ model }) => {
  return <Link to={`/cases/search?projectName=${model.projectName}`} className="btn btn-sm btn-outline-secondary">Find similar</Link>;
};
